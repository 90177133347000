import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import theme from "../../theme";
import HomeIcon from '@mui/icons-material/Home';


// Responsive drawer imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HouseIcon from '@mui/icons-material/House';
import Toolbar from "@mui/material/Toolbar";
import logo from "../../images/frugal_logo_with_name.png";
import { Link } from 'react-router-dom';
import AccountMenu from "./AccountMenu/AccountMenu";
import { makeStyles } from '@mui/styles';
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   menuButtonParent: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
   }
}));

export default function AppDrawer(props) {
   const classes = useStyles();
   const { window } = props;
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const [isClosing, setIsClosing] = React.useState(false);

   const handleDrawerClose = () => {
      setIsClosing(true);
      setMobileOpen(false);
   };

   const handleDrawerTransitionEnd = () => {
      setIsClosing(false);
   };

   const handleDrawerToggle = () => {
      if (!isClosing) {
         setMobileOpen(!mobileOpen);
      }
   };


   const drawer = (
      <div>
         <div style={{background: theme.palette.primary.main}}>
            <Toolbar>
               <Grid container justifyContent={"flex-start"}>
                  <img id="nav-logo" src={logo} alt="logo"/>
               </Grid>
            </Toolbar>
         </div>

         <Divider />
         <List>
            <ListItem key={"dashboard"} disablePadding>
               <ListItemButton component={Link} to='/dashboard'>
                  <ListItemIcon>
                     <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
               </ListItemButton>
            </ListItem>
            <ListItem key={"transactions"} disablePadding>
               <ListItemButton component={Link} to='/transactions'>
                  <ListItemIcon>
                     <ReceiptLongIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Transactions"} />
               </ListItemButton>
            </ListItem>
            <ListItem key={"Budgets"} disablePadding>
               <ListItemButton component={Link} to='/settings'>
                  <ListItemIcon>
                     <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Budgets"} />
               </ListItemButton>
            </ListItem>
            <ListItem key={"Family"} disablePadding>
               <ListItemButton component={Link} to='/account-family'>
                  <ListItemIcon>
                     <HouseIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Family"} />
               </ListItemButton>
            </ListItem>
            <ListItem key={"Contact"} disablePadding>
               <ListItemButton component={Link} to='/contact'>
                  <ListItemIcon>
                     <ContactSupportIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Contact"} />
               </ListItemButton>
            </ListItem>
         </List>
         <Divider />
         <List>
            <ListItem key={"Mortgage Calculator"} disablePadding>
               <ListItemButton component={Link} to='/mortgage-calculator'>
                  <ListItemIcon>
                     <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Mortgage Calculator"} />
               </ListItemButton>
            </ListItem>
         </List>
      </div>
   );

   // Remove this const when copying and pasting into your project.
   const container = window !== undefined ? () => window().document.body : undefined;

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <AppBar
            position="fixed"
            sx={{
               width: { sm: `calc(100% - ${drawerWidth}px)` },
               ml: { sm: `${drawerWidth}px` },
            }}
         >
            <Toolbar>
               <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
               >
                  <MenuIcon />
               </IconButton>

               <div className={classes.menuButtonParent}>
                  {/*<Link to="https://buymeacoffee.com/deremer" rel="noreferrer">*/}
                  {/*   <Tooltip title={"Buy me a coffee"}>*/}
                  {/*      <IconButton aria-label="buymeacoffee" color="secondary">*/}
                  {/*         <FreeBreakfastOutlinedIcon/>*/}
                  {/*      </IconButton>*/}
                  {/*   </Tooltip>*/}
                  {/*</Link>*/}

                  <AccountMenu></AccountMenu>
               </div>

            </Toolbar>

         </AppBar>

         <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
         >

            <Drawer
               variant="temporary"
               open={mobileOpen}
               onTransitionEnd={handleDrawerTransitionEnd}
               onClose={handleDrawerClose}
               ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
               }}
               sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
               }}
            >
               {drawer}
            </Drawer>

            <Drawer
               variant="permanent"
               sx={{
                  display: { xs: 'none', sm: 'block' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
               }}
               open
            >
               {drawer}
            </Drawer>
         </Box>
      </Box>
   );
}






