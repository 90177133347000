import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import theme from "../../theme";
import { makeStyles } from '@mui/styles';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   timeSelection: {
      display: 'flex',
   },
}));


export default function DateYearSelection(props) {
   const classes = useStyles();

   const handleYearChange = props.handleYearChange;
   const handleMonthChange = props.handleMonthChange;
   const month = props.month;
   const year = props.year;

   return (
      <Grid container spacing={2}>
         <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
               <InputLabel id="month-select-label">Month</InputLabel>
               <Select
                  labelId="month-select-label"
                  id="month-select"
                  value={month}
                  label="Month"
                  onChange={handleMonthChange}
               >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
               </Select>
            </FormControl>
         </Grid>
         <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
               <InputLabel id="year-select-label">Year</InputLabel>
               <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={handleYearChange}
               >
                  <MenuItem value={2023}>2023</MenuItem>
                  <MenuItem value={2024}>2024</MenuItem>
                  <MenuItem value={2025}>2025</MenuItem>
               </Select>
            </FormControl>
         </Grid>
      </Grid>

   );
}